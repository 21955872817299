import React from "react";

const FancyTitle = ({ title }) => {
  return (
    <div className="relative pb-4">
      <p className=" md:text-2xl text-[#1b2733] leading-[28px]  tracking-[0.5px] font-bold">
        {title}
      </p>
      <div className="flex gap-x-[5px] items-center absolute bottom-0">
        <div className="rounded-full w-[30px] h-[5px] bg-dsc-theme-accent"></div>
        <div className="w-[5px] h-[5px] rounded-full bg-dsc-theme-accent"></div>
        <div className="w-[5px] h-[5px] rounded-full bg-dsc-theme-accent"></div>
      </div>
    </div>
  );
};

export default FancyTitle;
