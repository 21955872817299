import React, { useEffect } from "react";
import parse from "html-react-parser";
import Container from "../../layout/container";
import { graphql, Link } from "gatsby";
import BlogSidebar from "../../components/blog-details/sidebar";
import Meta from "../../components/common/meta";
import moment from "moment";
import "moment/locale/ja";

const NewsDetails = (props) => {
  const { pageContext } = props;
  const { pageId: data } = pageContext;
  const htmls = data?.content?.data?.content;

  const regEx = /\/uploads\//g;
  const imageRegEx = /<img/g;
  const baseUrl = `https://strapi-admin.rundeck.digitalstacks.net`;
  const metaData = {
    title: `${data.title}｜Harness正規販売代理店 DXable`,
    url: `/new/${data.slug}`,
  };
  moment.locale("ja");
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll(".blog-details a").forEach((link) => {
        if (
          !link.href.includes("http://localhost:8000") ||
          !link.href.includes("https://rundeck.digitalstacks.net") ||
          !link.href.includes("https://rundeck.dxable.com")
        ) {
          link.setAttribute("target", "_blank");
        }
      });
    }
  }, []);
  return (
    <>
      <Meta meta={metaData} />

      <Container>
        <div className="py-0 ">
          <div className="py-[72px]">
            <div className="p-4 md:p-0">
              <div className="flex items-center mb-4 gap-x-4">
                <p className="text-[#47525D] text-[15px] leading-4 min-w-[110px]">
                  {moment(data?.releaseDate).format("LL")}
                </p>
              </div>
              <h1 className="dsc-h1 md:text-[40px] leading-[60px] font-bold mb-[46px] text-hn-blog-title">
                {data?.title}
              </h1>
              <div className="flex flex-col justify-between lg:flex-row gap-x-8 ">
                <div
                  className="dsc-text  prose-p:dsc-text prose-p:leading-7 prose-headings:my-4 prose-h1:dsc-h1 prose-h2:dsc-h2 prose-h2:leading-[36px] prose-h2:md:leading-[46px] prose-h3:dsc-h3 prose-h3:leading-7 hover:prose-a:text-dsc-theme-primary lg:max-w-[780px] md:w-full lg:w-[65%] prose-hr:my-4 prose-h2:mt-10
                     prose-h3:mt-10 prose-ul:pl-6 prose-ul:my-2 prose-ol:pl-6 prose-ol:my-2 prose-ol:list-decimal prose-ul:list-disc prose-pre:my-6 prose-pre:w-full prose-pre:whitespace-pre-wrap prose-pre:break-words prose-pre:p-2 prose-pre:bg-[#22272E] prose-pre:rounded-md prose-code:p-6"
                >
                  {parse(
                    htmls
                      .replaceAll(regEx, `${baseUrl}/uploads/`)
                      .replaceAll(imageRegEx, `<img loading="lazy" `)
                  )}

                  <div className="flex justify-between items-center no-underline hn-p2 leading-[18.75px] xl:max-w-[780px] my-12 mb-20 xl:mb-12">
                    {pageContext?.previousPageId !== "" && (
                      <Link
                        to={`${pageContext?.previousPagePath}`}
                        className="hover:text-dsc-theme-primary"
                      >
                        &lt; 前へ
                      </Link>
                    )}
                    {pageContext?.nextPageId !== "" && (
                      <Link
                        to={`${pageContext?.nextPagePath}`}
                        className="ml-auto hover:text-dsc-theme-primary"
                      >
                        次へ &gt;
                      </Link>
                    )}
                  </div>
                </div>
                <div className="lg:max-w-[343px] md:w-full lg:w-[35%]">
                  <BlogSidebar slug={data.slug} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NewsDetails;
