import { Link } from "gatsby";
import React from "react";
import FancyTitle from "./fancy-title";
import { useStaticQuery, graphql } from "gatsby";

const BlogSidebar = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query LatestPostQuery {
      allStrapiNew(limit: 5, sort: { fields: releaseDate, order: DESC }) {
        edges {
          node {
            id
            slug
            title
            releaseDate
          }
        }
      }
    }
  `);
  const populars = data.allStrapiNew.edges.filter((_) => _.node.slug !== slug);
  return (
    <div className="w-full">
      <div>
        <div className="blog-sidebar__title">
          <FancyTitle title={"最新の投稿"} />
        </div>
        <div className={`mb-20 xl:mb-6 my-6 flex flex-col gap-y-6 `}>
          {populars.map((latestBlog, index) => {
            return (
              <Link
                className="hover:text-dsc-theme-accent transition-all duration-300 ease-in-out text-base leading-[22px] tracking-[0.5px] dsc-text"
                to={`/new/${latestBlog.node.slug}`}
                key={latestBlog?.node?.id}
              >
                {latestBlog?.node?.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
